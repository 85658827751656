import PropTypes from "prop-types"
import React from "react"
import injectSheet from "react-jss"
import {Provider} from "react-redux"
import {Route, Router, Switch} from "react-router-dom"
import {Page} from "./constants/page/pages"
import {PagesArchitecture} from "./constants/page/pagesArchitecture"
import history from "./history"
import {AsyncTechnicalErrorScene} from "./layouts/asyncScenes"
import MainLayoutContainer from "./layouts/mainLayout"
import ErrorBoundaryContainer from "./shared-components/components/ErrorBoundary/errorBoundaryContainer"
import {MatomoTracker} from "./shared-components/components/MatomoTracker/matomoTracker"
import StyleLoaderContainer from "./shared-components/components/StyleLoader/styleLoaderContainer"
import TextsProvider from "./shared-components/components/TextsProvider/textsProvider"
import {SERVICE_BENEFICIARY_AREA} from "./shared-components/constants/areaTypes"
import sheet from "./shared-components/styles/globalSheet"
import injectTexts from "./shared-components/styles/injectTexts"
import {getCookiesChoices} from "./store/selectors/localStorage/cookiesSelectors"
import {getDynamicConfigurationValue} from "./store/selectors/localStorage/dynamicConfigurationSelectors"
import {areCustomizedStyle, getStyle} from "./store/selectors/localStorage/styleSelectors"
import {getTexts, isCustomizedTexts} from "./store/selectors/localStorage/textSelector"
import defaultStyle from "./styles/defaultStyle"
import texts from "./styles/globalText"
import {FullScreenLoader} from "./shared-components/components/FullScreenLoader/fullScreenLoader"

/**
 * Root of the application.
 * Set the provider to distribute the store in all components.
 * Set the router root.
 */
const Root = ({store, texts}) => (
    <Provider store={store}>
        <MatomoTracker getCookiesChoices={getCookiesChoices}>
            <TextsProvider texts={texts}>
                <Router history={history}>
                    <Switch>
                        <Route
                            component={AsyncTechnicalErrorScene}
                            exact
                            path={PagesArchitecture[Page.TECHNICAL_ERROR].route}
                        />
                        <StyleLoaderContainer
                            customStyle={defaultStyle}
                            espaceType={SERVICE_BENEFICIARY_AREA}
                            getDynamicConfigurationValue={getDynamicConfigurationValue}
                            getStyle={getStyle}
                            getTexts={getTexts}
                            isCustomizedStyle={areCustomizedStyle}
                            isCustomizedTexts={isCustomizedTexts}
                        >
                            <FullScreenLoader />
                            <ErrorBoundaryContainer routeToRedirect={PagesArchitecture[Page.HOME].route}>
                                <Route component={MainLayoutContainer} path="" />
                            </ErrorBoundaryContainer>
                        </StyleLoaderContainer>
                    </Switch>
                </Router>
            </TextsProvider>
        </MatomoTracker>
    </Provider>
)

Root.propTypes = {
    store: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
}

export default injectTexts(texts, injectSheet(sheet, {inject: ["sheet"]})(Root))

import {CommonRequestKeys} from "../../../../shared-components/common/constants/requests"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../../shared-components/store/requests/requestsReducer"
import {SELECT_AREA} from "./detailsActionsTypes"

const details = (state = {}, action = {}) => {
    switch (action.type) {
        case REQUEST_SUCCESS:
            if (action.requestKeyName === CommonRequestKeys.USER_INFO) {
                return {
                    civilite: action.payload.civilite,
                    nom: action.payload.nom,
                    prenom: action.payload.prenom,
                    beneficiaireId: action.payload.beneficiaireId,
                    espaces: action.payload.espaces,
                    selectedArea: state.selectedArea,
                    compteEBCESU: action.payload.compteEBCESU,
                }
            } else {
                return state
            }
        case REQUEST:
        case REQUEST_FAILURE:
            if (action.requestKeyName === CommonRequestKeys.USER_INFO) {
                return {selectedArea: state.selectedArea}
            } else {
                return state
            }
        case SELECT_AREA: {
            return {
                ...state,
                selectedArea: action.area,
            }
        }
        default:
            return state
    }
}

export default details

/**
 * Get user details from the store.
 */
export const getDetails = state => state

import {DISPLAY_FULLSCREEN_LOADER} from "../../../actions/sessionStorage/user/userActionTypes"

const displayFullScreenLoader = (state = false, action = {}) => {
    switch (action.type) {
        case DISPLAY_FULLSCREEN_LOADER:
            return action.response
        default:
            return state
    }
}

export default displayFullScreenLoader

export const getDisplayFullScreenLoader = state => state

import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 */
export const PagesArchitecture = {
    [Page.AREA_CHOICE]: {
        breadcrumbText: "Connexion",
        route: "/choix-espace-beneficiaire",
        userConnected: true,
    },
    [Page.BYPASS_LOGIN]: {
        route: "/bypass-login",
        userConnected: false,
    },
    [Page.CLIENT_CHOICE]: {
        breadcrumbText: "Connexion",
        route: "/choix-client",
        userConnected: true,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/",
        userConnected: true,
    },
    [Page.COMMANDE]: {
        breadcrumbText: "Commandes",
        route: "/commandes/choix-dispositif",
        userConnected: true,
    },
    [Page.COMMANDE_CONFIRMATION]: {
        breadcrumbText: "Confirmation",
        route: "/commandes/confirmation",
        userConnected: true,
    },
    [Page.COMMANDE_INFORMATIONS_PAIEMENT]: {
        title: "Informations paiement",
        route: "/commandes/informations-paiement",
        userConnected: true,
    },
    [Page.COMMANDE_PRESTATION]: {
        breadcrumbText: "Prestation",
        route: "/commandes/prestation",
        userConnected: true,
    },
    [Page.COMMANDE_CHOIX_DISPOSITIF]: {
        breadcrumbText: "Nouvelle commande",
        route: "/commandes/choix-dispositif",
        userConnected: true,
    },
    [Page.COMMANDE_INFORMATIONS_COMPLEMENTAIRES]: {
        breadcrumbText: "Informations complémentaires",
        route: "/commandes/informations-complementaires",
        userConnected: true,
    },
    [Page.COMMANDE_ATTRIBUTION]: {
        breadcrumbText: "Attribution",
        route: "/commandes/attribution",
        userConnected: true,
    },
    [Page.COMMANDE_SUIVI]: {
        breadcrumbText: "Historique",
        route: "/commandes",
        userConnected: true,
    },
    [Page.COMMANDE_VISUALISATION_MODIFICATION]: {
        breadcrumbText: "Détail de la commande",
        routeWithParamDesc: "/commandes/:numCommande",
        routeWithParam: numCommande => "/commandes/" + numCommande,
    },
    [Page.DEMANDE]: {
        breadcrumbText: "Demandes",
        route: "/demandes",
        userConnected: true,
    },
    [Page.DEMANDE_SUIVI]: {
        breadcrumbText: "Suivi des demandes",
        route: "/demandes",
        userConnected: true,
    },
    [Page.DEMANDE_CREATION]: {
        breadcrumbText: "Creation de demande",
        route: "/demandes",
        userConnected: true,
    },
    [Page.DOCUMENTS]: {
        breadcrumbText: "Documents",
        route: "/documents",
        userConnected: true,
    },
    [Page.CONTACT]: {
        breadcrumbText: "Contact",
        route: "/contact/informations",
        userConnected: true,
    },
    [Page.CONTACT_DEMANDE_INFORMATION]: {
        breadcrumbText: "Contact - Demande d'information",
        route: "/contact/demande-information",
        userConnected: true,
    },
    [Page.CONTACT_DEMANDE_RAPPEL]: {
        breadcrumbText: "Contact - Demande de rappel",
        route: "/contact/demande-rappel",
        userConnected: true,
    },
    [Page.CONTACT_INFORMATIONS]: {
        breadcrumbText: "Contact - Informations",
        route: "/contact/informations",
        userConnected: true,
    },
    [Page.FAQ]: {
        breadcrumbText: "Foire aux questions",
        route: "/faq",
        userConnected: true,
    },
    [Page.FORGOTTEN_LOGIN]: {
        breadcrumbText: "Identifiant oublié",
        route: "/identifiant-oublie",
    },
    [Page.FORGOTTEN_PASSWORD]: {
        breadcrumbText: "Mot de passe oublié",
        route: "/mot-de-passe-oublie",
    },

    [Page.RESET_PASSWORD]: {
        breadcrumbText: "Réinitialiser son mot de passe",
        routeWithParamDesc: "/mot-de-passe-oublie/token/:token",
        routeWithParam: token => "/mot-de-passe-oublie/token/" + token,
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/connexion",
        userConnected: false,
    },
    [Page.PROFILE]: {
        breadcrumbText: "Profil",
        route: "/profil/informations-principales",
        userConnected: true,
    },
    [Page.PROFILE_MAIN_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations principales",
        route: "/profil/informations-principales",
        userConnected: true,
    },
    [Page.PROFILE_COMPLEMENTARY_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations complémentaires",
        route: "/profil/informations-complementaires",
        userConnected: true,
    },
    [Page.PROFILE_CONNECTION_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations de connexion",
        route: "/profil/informations-connexion",
        userConnected: true,
    },
    [Page.MY_PROFILE_MAIN_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations principales",
        route: "/mon-profil/informations-principales",
        userConnected: true,
    },
    [Page.MY_PROFILE_RELATIVES_ADD_OR_EDIT]: {
        breadcrumbText: "Profil - Proches",
        route: "/mon-profil/proches/ajout-modification",
        userConnected: true,
    },
    [Page.MY_PROFILE_RELATIVES]: {
        breadcrumbText: "Profil - Proches",
        route: "/mon-profil/proches",
        userConnected: true,
    },
    [Page.MY_PROFILE_CONNECTION_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations de connexion",
        route: "/mon-profil/informations-connexion",
        userConnected: true,
    },
    [Page.MY_PROFILE_COMPLEMENTARY_INFORMATIONS]: {
        breadcrumbText: "Profil - Informations complémentaires",
        route: "/mon-profil/informations-complementaires",
        userConnected: true,
    },
    [Page.MY_PROFILE_ENVELOPE]: {
        breadcrumbText: "Profil - Enveloppe",
        route: "/mon-profil/enveloppe",
        userConnected: true,
    },
    [Page.REGISTRATION_PROCHE_PROFILE]: {
        breadcrumbText: "Inscription - Profil",
        route: "/inscription/profil-proche/token/:token",
    },
    [Page.REGISTRATION_BENEFICIAIRE_PROFILE]: {
        breadcrumbText: "Inscription - Profil",
        route: "/inscription/profil-beneficiaire/token/:token",
    },
    [Page.REGISTRATION_PASSWORD]: {
        breadcrumbText: "Inscription - Mot de passe",
        route: "/inscription/mot-de-passe",
    },
    [Page.LEGAL_NOTICE]: {
        parentDisconnect: Page.LOGIN,
        breadcrumbText: "Mentions légales",
        route: "/mentions-legales",
        parent: Page.HOME,
    },
    [Page.RGPD]: {
        parentDisconnect: Page.LOGIN,
        breadcrumbText: "Données personnelles",
        route: "/rgpd",
        parent: Page.HOME,
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.STYLE_GUIDE]: {
        breadcrumbText: "Style Guide",
        route: "/style-guide",
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
    },
}

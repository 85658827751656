import React from "react"
import { Loader } from "semantic-ui-react"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"
import "./fullScreenLoader.scss"
import { displayFullScreenLoader } from "../../store/localStorage/fullScreenLoader/displayFullScreenLoaderSelector"

export const FullScreenLoader = () => {
    const isVisible = useSelector(state => displayFullScreenLoader(state))

    return (
        !!isVisible && (
            <div className="loader-overlay">
                <Loader active={isVisible} />
            </div>
        )
    )
}

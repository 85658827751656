import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"
import {CLEAR_LOCAL_STORAGE_VALUE, SAVE_LOCAL_STORAGE_VALUE} from "./localStorageActionTypes"
import features, * as fromFeatures from "../../shared-components/store/localStorage/features/featuresReducer"
import {omit} from "lodash"
import displayFullScreenLoader, * as fromDisplayFullScreenLoader from "../../shared-components/store/localStorage/fullScreenLoader/fullScreenLoaderReducer"

/**
 * Permet de sauvegarder des valeurs de façon générique
 */
const genericLocalStorage = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_LOCAL_STORAGE_VALUE:
            return {
                ...state,
                [action.key]: action.value,
            }
        case CLEAR_LOCAL_STORAGE_VALUE:
            return omit(state, action.key)
        default:
            return state
    }
}

export const getLocalStorageValue = (state, key) => state.genericLocalStorage[key]

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    genericLocalStorage,
    cookies,
    displayFullScreenLoader,
    dynamicConfiguration,
    style,
    texts,
    features,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)

// FEATURES OPERATIONS
/**
 * Get enabled features
 */
export const getEnabledFeatures = state => fromFeatures.getEnabledFeatures(state.features)

export const getDisplayFullScreenLoader = state =>
    fromDisplayFullScreenLoader.getDisplayFullScreenLoader(state.displayFullScreenLoader)

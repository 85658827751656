/**
 * The pages of the application.
 */
export const Page = {
    AREA_CHOICE: "AREA_CHOICE",
    BYPASS_LOGIN: "BYPASS_LOGIN",
    CLIENT_CHOICE: "CLIENT_CHOICE",
    COMMANDE_ATTRIBUTION: "COMMANDE_ATTRIBUTION",
    COMMANDE_CHOIX_DISPOSITIF: "COMMANDE_CHOIX_DISPOSITIF",
    COMMANDE_CONFIRMATION: "COMMANDE_CONFIRMATION",
    COMMANDE_INFORMATIONS_COMPLEMENTAIRES: "COMMANDE_INFORMATIONS_COMPLEMENTAIRES",
    COMMANDE_PRESTATION: "COMMANDE_PRESTATION",
    COMMANDE_INFORMATIONS_PAIEMENT: "COMMANDE_INFORMATIONS_PAIEMENT",
    COMMANDE_SUIVI: "COMMANDE_SUIVI",
    COMMANDE_VISUALISATION_MODIFICATION: "COMMANDE_VISUALISATION_MODIFICATION",
    COMMANDE: "COMMANDE",
    CONTACT_DEMANDE_INFORMATION: "CONTACT_DEMANDE_INFORMATION",
    CONTACT_DEMANDE_RAPPEL: "CONTACT_DEMANDE_RAPPEL",
    CONTACT_INFORMATIONS: "CONTACT_INFORMATIONS",
    CONTACT: "CONTACT",
    DEMANDE_CREATION: "DEMANDE_CREATION",
    DEMANDE_SUIVI: "DEMANDE_SUIVI",
    DEMANDE: "DEMANDES",
    DOCUMENTS: "DOCUMENTS",
    FAQ: "FAQ",
    FORGOTTEN_LOGIN: "FORGOTTEN_LOGIN",
    FORGOTTEN_PASSWORD: "FORGOTTEN_PASSWORD",
    HOME: "HOME",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    MY_PROFILE_COMPLEMENTARY_INFORMATIONS: "MY_PROFILE_COMPLEMENTARY_INFORMATIONS",
    MY_PROFILE_CONNECTION_INFORMATIONS: "MY_PROFILE_CONNECTION_INFORMATIONS",
    MY_PROFILE_ENVELOPE: "MY_PROFILE_ENVELOPE",
    MY_PROFILE_MAIN_INFORMATIONS: "MY_PROFILE_MAIN_INFORMATIONS",
    MY_PROFILE_RELATIVES_ADD_OR_EDIT: "MY_PROFILE_RELATIVES_ADD_OR_EDIT",
    MY_PROFILE_RELATIVES: "MY_PROFILE_RELATIVES",
    NOT_FOUND: "NOT_FOUND",
    PROFILE_COMPLEMENTARY_INFORMATIONS: "PROFILE_COMPLEMENTARY_INFORMATIONS",
    PROFILE_CONNECTION_INFORMATIONS: "PROFILE_CONNECTION_INFORMATIONS",
    PROFILE_MAIN_INFORMATIONS: "PROFILE_MAIN_INFORMATIONS",
    PROFILE: "PROFILE",
    REGISTRATION_BENEFICIAIRE_PROFILE: "REGISTRATION_BENEFICIAIRE_PROFILE",
    REGISTRATION_PASSWORD: "REGISTRATION_PASSWORD",
    REGISTRATION_PROCHE_PROFILE: "REGISTRATION_PROCHE_PROFILE",
    RESET_PASSWORD: "RESET_PASSWORD",
    RGPD: "RGPD",
    STYLE_GUIDE: "STYLE_GUIDE",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",

    // EB UNIQUE
    ORDER_SESSION_LIST: "ORDER_SESSION_LIST",
    MILLESIME_TRADE_CHOICE: "MILLESIME_TRADE_CHOICE",
    CESU_CONVERSION_CHOICE: "CESU_CONVERSION_CHOICE",
    ORDER_HISTORY: "ORDER_HISTORY",
    ACCOUNT_STATEMENT: "ACCOUNT_STATEMENT",
    ACCOUNT_PAYMENT_CHOICE: "ACCOUNT_PAYMENT_CHOICE",
    ACCOUNT_PAYMENT_HISTORY: "ACCOUNT_PAYMENT_HISTORY",
    DECLARATION_HISTORY: "DECLARATION_HISTORY",
    CHECKBOOK_STATEMENT: "CHECKBOOK_STATEMENT",
    CHECK_PAYMENT_CHOICE: "CHECK_PAYMENT_CHOICE",
    CHECK_PAYMENT_HISTORY: "CHECK_PAYMENT_HISTORY",
    TAX_DOCUMENT: "TAX_DOCUMENT",
    USER_PROFILE_MAIN_INFORMATIONS: "USER_PROFILE_MAIN_INFORMATIONS",
}

export const EBSRequestKeys = {
    ADD_RELATIVE: "ADD_RELATIVE",
    CALCULATE_TOTAL_AMOUNT: "CALCULATE_TOTAL_AMOUNT",
    CANCEL_PRESTATION: "CANCEL_PRESTATION",
    CHECK_BENEFICIAIRE_REGISTRATION: "CHECK_BENEFICIAIRE_REGISTRATION",
    CHECK_BENEFICIAIRE_TOKEN: "CHECK_BENEFICIAIRE_TOKEN",
    CHECK_BENEFICIAIRE_CAN_CREATE_COMMANDE: "CHECK_BENEFICIAIRE_CAN_CREATE_COMMANDE",
    CHECK_PROCHE_REGISTRATION: "CHECK_PROCHE_REGISTRATION",
    CHECK_PROCHE_TOKEN: "CHECK_PROCHE_TOKEN",
    COMMANDE_CHECK_AGIRC_ATTRIBUTION: "COMMANDE_CHECK_AGIRC_ATTRIBUTION",
    COMMANDE_CHECK_ATTRIBUTION: "COMMANDE_CHECK_ATTRIBUTION",
    COMMANDE_CHECK_DEMANDE_URGENTE: "COMMANDE_CHECK_DEMANDE_URGENTE",
    COMMANDE_CREATE_AGIRC_PRESTATION: "COMMANDE_CREATE_AGIRC_PRESTATION",
    COMMANDE_CREATE_PRESTATION: "COMMANDE_CREATE_PRESTATION",
    CREATE_BENEFICIAIRE_USER_ACCOUNT: "CREATE_BENEFICIAIRE_USER_ACCOUNT",
    CREATE_DEMANDE_INFORMATION: "CREATE_DEMANDE_INFORMATION",
    CREATE_DEMANDE_RAPPEL: "CREATE_DEMANDE_RAPPEL",
    CREATE_PROCHE_USER_ACCOUNT: "CREATE_PROCHE_USER_ACCOUNT",
    DELETE_BENEFICIARY_RELATIVE: "DELETE_BENEFICIARY_RELATIVE",
    DELETE_RELATIVE: "DELETE_RELATIVE",
    EXPORT_COMMANDES: "EXPORT_COMMANDES",
    GET_BENEFICIAIRE_PROFILE_MAIN_INFORMATIONS: "GET_BENEFICIAIRE_PROFILE_MAIN_INFORMATIONS",
    GET_BENEFICIARY_AREA_TEL: "GET_BENEFICIARY_AREA_TEL",
    GET_BENEFICIARY_BILL_ATTACHMENT_BY_BILL_ID: "GET_BENEFICIARY_BILL_ATTACHMENT_BY_BILL_ID",
    GET_BENEFICIARY_COMPLEMENTARY_INFOS: "GET_BENEFICIARY_COMPLEMENTARY_INFOS",
    GET_BENEFICIARY_RELATIVES: "GET_BENEFICIARY_RELATIVES",
    GET_CGU: "GET_CGU",
    GET_CHEQUE_ACTIF: "GET_CHEQUE_ACTIF",
    GET_COMMANDE_DETAILS: "GET_COMMANDE_DETAILS",
    GET_COMMANDES_PRESTATION_FIELDS: "GET_COMMANDES_PRESTATION_FIELDS",
    GET_COMMANDES: "GET_COMMANDES",
    GET_COMPTEUR_BENEFICIAIRE: "GET_COMPTEUR_BENEFICIAIRE",
    GET_CONTRAT_INFO_COMP_FILLED_WITH_BENEF_INFOS: "GET_CONTRAT_INFO_COMP_FILLED_WITH_BENEF_INFOS",
    GET_CONTRAT_INFOS_ADDITIONNELLES: "GET_CONTRAT_INFOS_ADDITIONNELLES",
    GET_CONTRAT_QUESTIONS_ATTRIBUTION: "GET_CONTRAT_QUESTIONS_ATTRIBUTION",
    GET_CONTRAT_REGLES_INSTRUCTION: "GET_CONTRAT_REGLES_INSTRUCTION",
    GET_DISPLAY_FREQUENCY_ATTRIBUTIONS_RULES: "GET_DISPLAY_FREQUENCY_ATTRIBUTIONS_RULES",
    GET_DISPOSITIF_FOR_COMMANDE_AND_CLIENT_ID: "GET_DISPOSITIF_FOR_COMMANDE_AND_CLIENT_ID",
    GET_DISPOSITIFS_WITH_COUNTER_FOR_BENEF_AND_CLIENT_ID: "GET_DISPOSITIFS_WITH_COUNTER_FOR_BENEF_AND_CLIENT_ID",
    GET_DISTANCE_BETWEEN_ADRESSES: "GET_DISTANCE_BETWEEN_ADRESSES",
    GET_ESPACE_BENEFICIARY_URL: "GET_ESPACE_BENEFICIARY_URL",
    GET_ESPACE_BENEFICIARY_SERVICE_URL: "GET_ESPACE_BENEFICIARY_SERVICE_URL",
    GET_FACTURES_FOR_COMMANDE_AND_BENEF_ID: "GET_FACTURES_FOR_COMMANDE_AND_BENEF_ID",
    GET_FAQ: "GET_FAQ",
    GET_LAST_ENABLE_ZONE_INTERVENTION: "GET_LAST_ENABLE_ZONE_INTERVENTION",
    GET_PROCHE_PROFILE_MAIN_INFORMATIONS: "GET_PROCHE_PROFILE_MAIN_INFORMATIONS",
    GET_MANDAT_BENEFICIAIRE_ACTIF: "GET_MANDAT_BENEFICIAIRE_ACTIF",
    PERSONNALISATION_LOGO: "PERSONNALISATION_LOGO",
    PERSONNALISATION_CLIENTS_LOGOS: "PERSONNALISATION_CLIENTS_LOGOS",
    PERSONNALISATION: "PERSONNALISATION",
    REFS_GROUPE_IRC_AGIRC: "REFS_GROUPE_IRC_AGIRC",
    REFS_LIBELLES_TYPE_INFO_COMPL: "REFS_LIBELLES_TYPE_INFO_COMPL",
    REFS_TYPE_INFO_COMPL: "REFS_TYPE_INFO_COMPL",
    RETRIEVE_ELIGIBLE_ZONES_INTERVENTION: "RETRIEVE_ELIGIBLE_ZONES_INTERVENTION",
    SCELLIUS_GET_DATA: "SCELLIUS_GET_DATA",
    SCELLIUS_REDIRECTION_FORMULAIRE_MANDAT: "SCELLIUS_REDIRECTION_FORMULAIRE_MANDAT",
    SEARCH_COMMANDES_NUMBER: "SEARCH_COMMANDES_NUMBER",
    SEARCH_DISPOSITIF: "SEARCH_DISPOSITIF",
    UPDATE_BENEFICIAIRE_PROFILE_MAIN_INFORMATIONS: "UPDATE_BENEFICIAIRE_PROFILE_MAIN_INFORMATIONS",
    UPDATE_BENEFICIARY_COMPLEMENTARY_INFOS: "UPDATE_BENEFICIARY_COMPLEMENTARY_INFOS",
    UPDATE_BENEFICIARY_RELATIVE: "UPDATE_BENEFICIARY_RELATIVE",
    UPDATE_PRESTATION: "UPDATE_PRESTATION",
    UPDATE_PROCHE_PROFILE_INFORMATIONS_CONNEXION: "UPDATE_PROCHE_PROFILE_INFORMATIONS_CONNEXION",
    UPDATE_PROCHE_PROFILE_MAIN_INFORMATIONS: "UPDATE_PROCHE_PROFILE_MAIN_INFORMATIONS",
    UPDATE_RELATIVE: "UPDATE_RELATIVE",
}

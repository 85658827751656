/***************************************/
/*              SERVICES               */
/***************************************/

const SERVICE_API = "/api/service"
const AUTH = "/auth"
const BENEFICIARIES = "/beneficiaires"
const FEATURES = "/fonctionnalites"
const INTERVENANTS = "/intervenants"
const UTIL = "/util"
const MILLESIME_TRADE = "/echange-millesime"
const REPORT = "/rapports"
const PASSWORD = "/password"
const PREORDER_SESSIONS = "/sessions-precommande"
const SEARCH = "/search"
const STATISTICS = "/statistiques"
const URSSAF = "/urssaf"
const TUTORS = "/tuteurs"
const SERVIDOM = "/servidom"
const EBS = "/api/espace-beneficiaire-service/service"

/**
 * Endpoint to get intervenant for pre registration control (got by nanCode/nanKey and Birthdate or SIRET)
 */
export const GET_INTERVENANT_FOR_PRE_REGISTRATION_CONTROL_ENDPOINT =
    SERVICE_API + INTERVENANTS + "/controle-pre-inscription"

/**
 * Endpoint to get intervenant profile
 */
export const INTERVENANT_PROFILE_ENDPOINT = SERVICE_API + INTERVENANTS + "/profil"

/**
 * Endpoint to get a file of customization.
 */
export const GET_CUSTOMIZATION_FILE_ENDPOINT = "/api/service/filer/personnalisation"

/**
 * Endpoint to download a file from the filer Arborescence
 */
export const FILER_DOWNLOAD_ARBORESCENCE_ENDPOINT = "/api/service/filer/telecharge-arborescence"

/**
 * Endpoint to download a file from the filer Arborescence
 */
export const FILER_DOWNLOAD_FACTURE_ENDPOINT = "/api/service/filer/telecharge-facture"

/**
 * Endpoint to download a file from the filer Demande Gestion
 */
export const FILER_DOWNLOAD_DEMANDE_GESTION_ENDPOINT = "/api/service/filer/telecharge-demande-gestion"

/**
 * Endpoint to download a file from the filer Demande Remise
 */
export const FILER_DOWNLOAD_REMISE_ENDPOINT = "/api/service/filer/telecharge-remise"

/**
 * Endpoint to download a file from the filer
 */
export const FILER_DOWNLOAD_DEPOT_COMMANDE_ENDPOINT = "/api/service/filer/telecharge-depot-commandes"

/**
 * Endpoint to download a file from the filer Demande Remise
 */
export const FILER_DOWNLOAD_PIECE_DEPOT_COMMANDE_ENDPOINT = "/api/service/filer/telecharge-piece-depot-commandes"

/**
 * Endpoint to connect an user.
 */
export const CONNECT_USER_ENDPOINT = "/api/service/auth/login"

/**
 * Endpoints for AuthAPI
 */
export const AUTH_ENDPOINTS = {
    bypassLoginEBU: SERVICE_API + AUTH + "/bypass-login-ebu",
    bypassLogin: SERVICE_API + AUTH + "/bypass-login",
    logout: `${SERVICE_API}${AUTH}/logout`,
    recoverLoginBeneficiaryArea: SERVICE_API + AUTH + "/recover/login",
    recoverLoginPassword: SERVICE_API + AUTH + "/recover",
    getParamBypass: SERVICE_API + AUTH + "/bypass/param",
}

/**
 * Endpoints appel de Sidomi vers servidom
 */
export const SERVIDOM_ENDPOINTS = {
    bypassServidom: SERVICE_API + SERVIDOM + "/bypass-token",
    getBeneficiaryCounters: SERVICE_API + SERVIDOM + "/beneficiaire/client/compteurs",
    getCommandes: SERVICE_API + SERVIDOM + `/beneficiaire/commandes`,
    getContratFaq: numeroContrat => SERVICE_API + SERVIDOM + "/contrat/" + numeroContrat + "/faq",
}

/**
 * Endpoints appel de servidom vers Sidomi
 */
export const SIDOMI_ENDPOINTS = {
    bypassSidomi: "/api/sidomi/service/bypass-token",
}

/**
 * Endpoint to fetch information about the connected user
 */
export const GET_USER_INFO_ENDPOINT = "/api/service/auth/connected"

/**
 * Endpoint to fetch profile information about the connected user
 */
export const GET_USER_PROFILE_INFO_ENDPOINT = "/api/service/compte-utilisateur/profil/informations"

/**
 * Endpoint to search for a beneficiary.
 */
export const SEARCH_BENEFICIARY_ENDPOINT = SERVICE_API + SEARCH + "/beneficiary"
/**
 * Endpoint to search for a beneficiary linked to a contractId.
 */
export const SEARCH_MANAGEMENT_REQUEST_BENEFICIARY_ENDPOINT = SERVICE_API + SEARCH + "/demande-gestion-beneficiaires"
/**
 * Endpoint to search for a town.
 */
export const SEARCH_TOWN_ENDPOINT = SERVICE_API + SEARCH + "/town"

/**
 * Endpoint to validate a siret.
 */
export const SIRET_VALIDATION_ENDPOINT = "/api/service/siret-validation/validate"

/**
 * Password endpoints
 */
export const PASSWORD_ENDPOINTS = {
    changeActualPassword: SERVICE_API + PASSWORD + "/modification",
    changePassword: SERVICE_API + PASSWORD + "/update",
}
/**
 * Endpoint for profile informations of connected user
 */
export const PROFILE_INFO_ENDPOINT = "/api/service/profil"

/**
 * Endpoint to get the rules to calculate invoices.
 */
export const GET_RULES_INVOICE_CALCULATIONS = "/api/service/calculs-facturation/regles"

/**
 * Endpoint to get the TVA to calculate invoices.
 */
export const GET_TVA_INVOICE_CALCULATIONS = "/api/service/calculs-facturation/tva"

/**
 * Endpoint to save online orders.
 */
export const SAVE_ONLINE_ORDER = "/api/service/commande-en-ligne"

/**
 * Endpoint to calculate online orders costs.
 */
export const CALCULATE_ONLINE_ORDER_COSTS = "/api/service/commande-en-ligne/couts"

/**
 * Endpoint to get online orders results.
 */
export const GET_ONLINE_ORDER_RESULTS = "/api/service/commande-en-ligne/resultats"

/**
 * Scellius endpoints.
 */
export const SCELLIUS_ENDPOINTS = {
    beneficiaryAccountTradeOrder: SERVICE_API + "/scellius/espace-beneficiaire/initialiser-paiement-echange-millesime",
    beneficiaryIntervenantAccountPayment:
        SERVICE_API + "/scellius/espace-beneficiaire/initialiser-paiement-compte-intervenant",
    beneficiaryPreOrderData: SERVICE_API + "/scellius/espace-beneficiaire/initialiser-paiement-precommande",
    onlineOrderData: SERVICE_API + "/scellius/espace-financeur/initialiser-paiement",
    tradeOrderData: SERVICE_API + "/scellius/espace-echange/initialiserPaiement",
    transactionState: SERVICE_API + "/scellius/etat-paiement",
}

/**
 * Endpoint to get duplication information.
 */
export const GET_DUPLICATION_INFORMATION_ENDPOINT = SERVICE_API + "/commande-en-ligne/information-duplication"

/**
 * Endpoint to generate and download an excel export
 */
export const EXCEL_DOWNLOAD_ENDPOINT = SERVICE_API + "/excel/export"

/**
 * Endpoints to get a reports tree.
 */
export const GET_REPORTS_TREE_ENDPOINT_START = SERVICE_API + REPORT + "/"
export const GET_REPORTS_TREE_ENDPOINT_END = "/arbre"

/**
 * Endpoints to save a new file in a reports tree.
 */
export const SAVE_NEW_REPORTS_FILE_ENDPOINT_START = SERVICE_API + REPORT + "/"
export const SAVE_NEW_REPORTS_FILE_ENDPOINT_END = "/ajout/fichier"

/**
 * Endpoints to save a new folder in a reports tree.
 */
export const SAVE_NEW_REPORTS_FOLDER_ENDPOINT_START = SERVICE_API + REPORT + "/"
export const SAVE_NEW_REPORTS_FOLDER_ENDPOINT_END = "/ajout/dossier"

/**
 * Endpoints to delete a file from a reports tree.
 */
export const DELETE_REPORTS_FILE_ENDPOINT_START = SERVICE_API + REPORT + "/"
export const DELETE_REPORTS_FILE_ENDPOINT_END = "/retrait/fichier"

/**
 * Endpoints to delete a folder from a reports tree.
 */
export const DELETE_REPORTS_FOLDER_ENDPOINT_START = SERVICE_API + REPORT + "/"
export const DELETE_REPORTS_FOLDER_ENDPOINT_END = "/retrait/dossier"

/**
 * Environment endpoints
 */
export const ENVIRONMENT_ENDPOINTS = {
    beneficiaryServiceUrl: SERVICE_API + "/environment/espace-beneficiaire-service/url",
    beneficiaryUrl: SERVICE_API + "/environment/espace-beneficiaire/url",
    beneficiaryRegistrationUrl: SERVICE_API + "/environment/espace-beneficiaire/url-inscription",
    beneficiaryLoginUrl: SERVICE_API + "/environment/espace-beneficiaire/login/url",
}

/**
 * Endpoint to create or update a preOrderSession
 * @type {[type]}
 */
export const PREORDER_SESSION_CREATE_UPDATE_ENDPOINT = SERVICE_API + PREORDER_SESSIONS + "/creation-modification"

/**
 * Endpoint for handOver checkbooks
 */
export const INTERVENANTS_ENDPOINTS = {
    changeActualPassword: SERVICE_API + INTERVENANTS + "/profil/infos-connexion",
    createSimpleHandOver: SERVICE_API + INTERVENANTS + "/remises/simple",
    createBarCodeHandOver: SERVICE_API + INTERVENANTS + "/remises/codebarre",
    createFileHandOver: SERVICE_API + INTERVENANTS + "/remises/fichier",
    generateBillTransfer: reglementId => SERVICE_API + INTERVENANTS + "/remboursements/titres/" + reglementId + "/pdf",
    generatePdf: remiseId => SERVICE_API + INTERVENANTS + "/remises/" + remiseId + "/pdf",
    profileMainInformations: SERVICE_API + INTERVENANTS + "/profil/infos-principales",
    profileRefundInformations: SERVICE_API + INTERVENANTS + "/profil/infos-remboursement",
    retrieveAccountPayments: SERVICE_API + INTERVENANTS + "/remboursements/compte",
    retrieveCheckTransfers: SERVICE_API + INTERVENANTS + "/remboursements/titres",
    retrieveFileRefunds: SERVICE_API + INTERVENANTS + "/remises/fichier/suivi",
    retrieveRefund: refundId => SERVICE_API + INTERVENANTS + "/remises/" + refundId,
    retrieveRefunds: SERVICE_API + INTERVENANTS + "/remises",
    retrieveTransferDetails: transferId => SERVICE_API + INTERVENANTS + "/remboursements/reglements/" + transferId,
    validateCheckbook: SERVICE_API + INTERVENANTS + "/titres/validation/simple",
    validateCheckbookListWithBarcode: SERVICE_API + INTERVENANTS + "/titres/validation/codebarres",
    validateCheckbookWithBarcode: SERVICE_API + INTERVENANTS + "/titres/validation/codebarre",
}

/**
 * Features endpoints
 */
export const FEATURES_ENDPOINTS = {
    getBeneficiaryEnabledFeatures: SERVICE_API + FEATURES + BENEFICIARIES,
    getBeneficiaryEnabledFeaturesSidomi: beneficiaryId =>
        "/api/sidomi/service" + FEATURES + BENEFICIARIES + "/" + beneficiaryId,
}

/**
 * Statistics financeur endpoints
 */
const FINANCEUR_STATISTICS = SERVICE_API + STATISTICS + "/financeur"
export const STATISTICS_FINANCEUR_ENDPOINTS = {
    generalData: () => FINANCEUR_STATISTICS + "/donnees-generales",
    beneficiaryStats: beneficiaryId => FINANCEUR_STATISTICS + BENEFICIARIES + "/" + beneficiaryId + "/statistiques",
    beneficiaryAccountsHistory: beneficiaryId =>
        FINANCEUR_STATISTICS + BENEFICIARIES + "/" + beneficiaryId + "/historiques/comptes",
    beneficiaryTitlesHistory: beneficiaryId =>
        FINANCEUR_STATISTICS + BENEFICIARIES + "/" + beneficiaryId + "/historiques/titres",
    intervenantAccountsHistory: intervenantId =>
        FINANCEUR_STATISTICS + INTERVENANTS + "/" + intervenantId + "/historiques/comptes",
    intervenantAccountsStats: intervenantId =>
        FINANCEUR_STATISTICS + INTERVENANTS + "/" + intervenantId + "/statistiques/comptes",
    intervenantTitlesHistory: intervenantId =>
        FINANCEUR_STATISTICS + INTERVENANTS + "/" + intervenantId + "/historiques/titres",
    intervenantTitlesStats: intervenantId =>
        FINANCEUR_STATISTICS + INTERVENANTS + "/" + intervenantId + "/statistiques/titres",
}

/**
 * Millesime Trade Endpoints
 */
export const MILLESIME_TRADE_ENDPOINTS = {
    calculateTradingFees: SERVICE_API + MILLESIME_TRADE + "/calculer-frais",
    checkSecretCodeTitle: SERVICE_API + MILLESIME_TRADE + "/titres/case-a-gratter",
    checkValidTitles: SERVICE_API + MILLESIME_TRADE + "/titres/validation",
    getMillesimeInTrade: SERVICE_API + MILLESIME_TRADE + "/millesime-en-periode",
    validateOrder: SERVICE_API + MILLESIME_TRADE + "/valider-commande",
}

/**
 * Util endpoints
 */
export const UTIL_ENDPOINTS = {
    calculateFacialValues: SERVICE_API + UTIL + "/calculer-valeur-faciale",
}

/**
 * Beneficiary Order Endpoints
 */
export const BENEFICIARY_ENDPOINTS = {
    calculateReloadingFees: SERVICE_API + BENEFICIARIES + "/paiements/comptes/calculer-frais",
    calculPaymentComplementFees: SERVICE_API + BENEFICIARIES + "/paiements/comptes/calculer-frais-complement",
    cancelOrder: sessionId => SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/annuler",
    changeActualPassword: SERVICE_API + BENEFICIARIES + "/profil/infos-connexion",
    createOrUpdatePreOrder: sessionId => SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/precommande",
    checkControlInformations: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/verification-infos-complementaires",
    checkControlInformationsForRegistration: (contractId, sessionId) =>
        SERVICE_API +
        BENEFICIARIES +
        "/inscription/" +
        contractId +
        (sessionId ? "/sessions/" + sessionId : "") +
        "/infos",
    checkIfAlreadyRegistered: (contractId, sessionId) =>
        SERVICE_API +
        BENEFICIARIES +
        "/inscription/" +
        contractId +
        (sessionId ? "/sessions/" + sessionId : "") +
        "/deja-inscrit",
    checkSecretCode: SERVICE_API + BENEFICIARIES + "/transformation/titres/case-a-gratter",
    convertAccountToChecks: SERVICE_API + BENEFICIARIES + "/transformation/compte-en-titres",
    convertChecksToAccount: SERVICE_API + BENEFICIARIES + "/transformation/titres-en-compte",
    downloadAccountStatementPDF: cesuAccountNumber =>
        SERVICE_API + BENEFICIARIES + "/consultation/comptes/" + cesuAccountNumber + "/releve/pdf",
    downloadPaymentRequestPDF: paymentRequestId =>
        SERVICE_API + BENEFICIARIES + "/paiements/comptes/" + paymentRequestId + "/pdf",
    downloadBeneficiaryCheckPaymentSlipPDF: refundNumber =>
        SERVICE_API + BENEFICIARIES + "/paiements/titres/" + refundNumber + "/pdf",
    getBeneficiaryPart: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/calculer-part-beneficiaire",
    getContracts: SERVICE_API + BENEFICIARIES + "/contrats",
    getOrderCeiling: sessionId => SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/calculer-max-precommande",
    getOrderOverviewFromSession: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/commande-details",
    getOrderInformationsFromSession: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/commande-informations",
    getRegistrationParameters: contractId => SERVICE_API + BENEFICIARIES + "/inscription/" + contractId,
    getSessionsOfContract: contractId => SERVICE_API + BENEFICIARIES + "/inscription/" + contractId + "/sessions",
    getSessionComplementaryInfoForBeneficiary: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/infos-complementaires",
    getSessionDeliveryInformations: sessionId =>
        SERVICE_API + BENEFICIARIES + "/sessions/" + sessionId + "/infos-livraison",
    hasAccountsOrCheckbooksConvertibles: SERVICE_API + BENEFICIARIES + "/transformation",
    makeBeneficiaryAccountPaymentRequest: SERVICE_API + BENEFICIARIES + "/paiements/comptes/demande",
    makeBeneficiaryCheckPaymentRequest: SERVICE_API + BENEFICIARIES + "/paiements/titres/remise",
    makeBeneficiaryRegistration: (contractId, sessionId) =>
        SERVICE_API + BENEFICIARIES + "/inscription/" + contractId + (sessionId ? "/sessions/" + sessionId : ""),
    updateMainInformationsProfile: SERVICE_API + BENEFICIARIES + "/profil/infos-principales",
    retrieveActiveCesuAccounts: SERVICE_API + BENEFICIARIES + "/comptes",
    retrieveMaxCreditCardSlice: SERVICE_API + BENEFICIARIES + "/paiements/comptes/montant-max",
    retrieveOpenedSessions: SERVICE_API + BENEFICIARIES + "/sessions",
    retrieveOrderClients: SERVICE_API + BENEFICIARIES + "/commandes/financeurs",
    retrieveOrderDetails: orderId => SERVICE_API + BENEFICIARIES + "/commandes/" + orderId,
    retrieveOrderHistory: SERVICE_API + BENEFICIARIES + "/commandes",
    retrieveAccountPaymentHistory: SERVICE_API + BENEFICIARIES + "/paiements/comptes",
    retrieveCheckPaymentHistory: SERVICE_API + BENEFICIARIES + "/paiements/titres",
    retrievePaidIntervenants: SERVICE_API + BENEFICIARIES + "/paiements/intervenants",
    retrieveProductTypeChoice: SERVICE_API + BENEFICIARIES + "/choix-type-produit",
    retrieveBeneficiarySearchedIntervenants: SERVICE_API + BENEFICIARIES + "/paiements/recherche/intervenants",
    retrieveUsableCesuChecks: SERVICE_API + BENEFICIARIES + "/titres",
    retrieveBeneficiaryAccountsForStatement: SERVICE_API + BENEFICIARIES + "/consultation/comptes",
    retrieveBeneficiaryAccountStatement: accountNumber =>
        SERVICE_API + BENEFICIARIES + "/consultation/comptes/" + accountNumber + "/releve",
    retrieveBeneficiaryCheckbookStatement: productId =>
        SERVICE_API + BENEFICIARIES + "/consultation/titres/" + productId + "/releve",
    retrieveBeneficiaryCheckbooksForStatement: SERVICE_API + BENEFICIARIES + "/consultation/titres",
    retrieveBeneficiaryConvertiblesAccounts: SERVICE_API + BENEFICIARIES + "/transformation/produits-comptes",
    retrieveBeneficiaryConvertiblesCheckbooks: SERVICE_API + BENEFICIARIES + "/transformation/produits-titres",
    retrieveBeneficiaryCheckbooksForIntervenantPayment:
        SERVICE_API + BENEFICIARIES + "/paiements/titres/produits-titres",
    retrieveBeneficiaryChecksForIntervenantPayment: productId =>
        SERVICE_API + BENEFICIARIES + "/paiements/titres/produits-titres/" + productId + "/titres",
    retrieveBeneficiaryConvertiblesChecks: productId =>
        SERVICE_API + BENEFICIARIES + "/transformation/produits-titres/" + productId + "/titres",
    updateProductTypeChoice: SERVICE_API + BENEFICIARIES + "/choix-type-produit",
    retrieveTaxDocument: SERVICE_API + BENEFICIARIES + "/attestation-fiscale/liste",
    downloadTaxDocumentPDF: taxDocumentId =>
        SERVICE_API + BENEFICIARIES + "/attestation-fiscale/" + taxDocumentId + "/pdf",
}

/**
 * Beneficiary millesime trade endpoints
 */
export const BENEFICIARY_MILLESIME_TRADE_ENDPOINTS = {
    calculateTradingFees: SERVICE_API + BENEFICIARIES + "/echange-millesime/calculer-frais",
    generateTradeBill: transactionReference =>
        SERVICE_API + BENEFICIARIES + "/echange-millesime/factures/" + transactionReference + "/pdf",
    retrieveBeneficiaryAccountsForMillesimeTrade: SERVICE_API + BENEFICIARIES + "/echange-millesime/comptes",
    retrieveTradeParametersByCesuAccountNumber: cesuAccountNumber =>
        SERVICE_API + BENEFICIARIES + "/echange-millesime/comptes/" + cesuAccountNumber + "/parametres-echange",
    validateOrder: SERVICE_API + BENEFICIARIES + "/echange-millesime/valider-commande",
}

/**
 * PreOrder Session Endpoints
 */
export const PREORDER_SESSION_ENDPOINTS = {
    closePreOrderSession: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/cloturer",
    createPreOrderSessionOrder: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/commander",
    getAllPreOrderSessions: contractId => ENTITY_API + CONTRACT + "/" + contractId + PREORDER_SESSIONS,
    getPreOrderSessionDetails: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId,
    getPreOrderSessionInformation: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/information",
    getPreOrderSessionLines: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/lignes",
    getPreOrderSessionOrders: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/commandes",
    getPreOrderSessionMatricule: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/matricule",
    getPreOrderSessionStatistics: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/statistiques",
    updatePreOrderSessionLines: sessionId => SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/maj/lignes",
    validationControleFile: contractNumber => SERVICE_API + PREORDER_SESSIONS + "/" + contractNumber + "/control-file",
    validatePreOrderAllWaitingLines: sessionId =>
        SERVICE_API + PREORDER_SESSIONS + "/" + sessionId + "/valider/lignes-en-attentes",
}

/**
 * Urssaf Cesu Endpoints
 */
export const URSSAF_CESU_ENDPOINTS = {
    checkEmployeeAccount: SERVICE_API + URSSAF + "/cesu/verifier-compte-salarie",
    checkEmployerAccount: cesuNumber => SERVICE_API + URSSAF + "/cesu/verifier-compte-employeur/" + cesuNumber,
    downloadDeclarationReceipt: declarationId => SERVICE_API + URSSAF + "/cesu/teledeclaration/" + declarationId,
    getInfoFromLastDeclaration: SERVICE_API + URSSAF + "/cesu/infos-identification",
    getLastCesuCodeAndMandateState: SERVICE_API + URSSAF + "/cesu/infos-profil-declaration",
    makeDeclaration: cesuNumber => SERVICE_API + URSSAF + "/cesu/teledeclaration/" + cesuNumber,
    retrieveDeclarationsHistory: SERVICE_API + URSSAF + "/cesu/teledeclarations",
    retrieveDeclaredEmployeeCesuNumbers: SERVICE_API + URSSAF + "/cesu/teledeclarations/salaries",
    retrieveDeclaredEmployerCesuNumbers: SERVICE_API + URSSAF + "/cesu/teledeclarations/employeurs",
    updateMandateState: SERVICE_API + URSSAF + "/cesu/mandat",
}

/**
 * Urssaf Paje Endpoints
 */
export const URSSAF_PAJE_ENDPOINTS = {
    checkAndAddChildren: pajeNumber => SERVICE_API + URSSAF + "/paje/enfants/" + pajeNumber,
    checkChildById: (pajeNumber, childId) => SERVICE_API + URSSAF + "/paje/enfants/" + pajeNumber + "/" + childId,
    checkEmployeeAccount: SERVICE_API + URSSAF + "/paje/verifier-compte-salarie",
    checkEmployerAccount: pajeNumber => SERVICE_API + URSSAF + "/paje/verifier-compte-employeur/" + pajeNumber,
    downloadDeclarationReceipt: declarationId => SERVICE_API + URSSAF + "/paje/teledeclaration/" + declarationId,
    getInfoFromLastDeclaration: SERVICE_API + URSSAF + "/paje/infos-identification",
    getLastDeclaration: pajeNumber => SERVICE_API + URSSAF + "/paje/teledeclaration/" + pajeNumber + "/derniere",
    getLastPajeCodeAndMandateState: SERVICE_API + URSSAF + "/paje/infos-profil-declaration",
    makeAmaDeclaration: pajeNumber => SERVICE_API + URSSAF + "/paje/teledeclaration/ama/" + pajeNumber,
    makeAmaPreDeclaration: pajeNumber => SERVICE_API + URSSAF + "/paje/pre-teledeclaration/ama/" + pajeNumber,
    makeAssociation: SERVICE_API + URSSAF + "/paje/association",
    makeGedDeclaration: pajeNumber => SERVICE_API + URSSAF + "/paje/teledeclaration/ged/" + pajeNumber,
    makeGedPreDeclaration: pajeNumber => SERVICE_API + URSSAF + "/paje/pre-teledeclaration/ged/" + pajeNumber,
    retrieveChildren: pajeNumber => SERVICE_API + URSSAF + "/paje/enfants/" + pajeNumber,
    retrieveDeclarationsHistory: SERVICE_API + URSSAF + "/paje/teledeclarations",
    retrieveDeclaredEmployeePajeNumbers: SERVICE_API + URSSAF + "/paje/teledeclarations/salaries",
    retrieveDeclaredEmployerPajeNumbers: SERVICE_API + URSSAF + "/paje/teledeclarations/employeurs",
    updateMandateState: SERVICE_API + URSSAF + "/paje/mandat",
    retrievePajeDeclarationHistory: SERVICE_API + URSSAF + "/paje/teledeclarations",
}

/***************************************/
/*              ENTITIY                */
/***************************************/

const ENTITY_API = "/api/entity"

const BANKS = "/banques"
const BANK_ACCOUNTS = "/compte-bancaires"
const BILLS = "/factures"
const CONTRACT = "/contrat-client"
const CLIENT = "/clients"
const KINDS_WAY_ADDRESS = "/genres-voie-adresse"
const LEGAL_FORMS = "/formes-juridique"
const MANAGEMENT_REQUEST = "/demande-gestion"
const ORDERS = "/commandes"
const ORDER_LINES = "/lignes-commandes"
const ORDER_REASONS = "/ref-motif-commande"
const ORDER_TYPES = "/ref-type-commande"
const PARAMETERS = "/parametres"
const TITLE_STATE_TYPES = "/ref-etat-titre-cesu"
const TYPES_WAY_ADDRESS = "/types-voie-adresse"
const WEB_ACCESS = "/acces-web"

/**
 * CLIENT ENDPOINTS
 */
export const CLIENT_ENDPOINTS = {
    retrieveLinkedWebAccesses: contractId => ENTITY_API + CONTRACT + "/" + contractId + WEB_ACCESS,
    getClientInformations: clientId => ENTITY_API + CLIENT + "/" + clientId,
    getParamEspaceDomiserveByClientId: clientId => ENTITY_API + CLIENT + "/" + clientId + "/param-espace-domiserve",
}

/**
 * PARAMETERS ENDPOINTS
 */
export const PARAMETERS_ENDPOINTS = {
    DOMISERVE: {
        getRib: ENTITY_API + PARAMETERS + "/domiserve/rib",
        getUrlEbs: ENTITY_API + PARAMETERS + "/domiserve/url/ebs",
        getUrlEbc: ENTITY_API + PARAMETERS + "/domiserve/url/ebc",
    },
}

/**
 * Endpoint to get the customized style.
 */
export const GET_STYLE_ENDPOINT = "/api/entity/personnalisation/style"

/**
 * Endpoint to get the customized texts.
 */
export const GET_TEXTS_ENDPOINT = "/api/entity/personnalisation/texte"

/**
 * Endpoint for beneficiaries of a contract.
 */
export const GET_BENEFICIARIES_ENDPOINT_START = "/api/entity/contrat-client/"
export const GET_BENEFICIARIES_ENDPOINT_END = "/beneficiaires"
export const GET_BENEFICIARIES_SEARCH_ENDPOINT_END = "/recherche"
export const PATCH_BENEFICIARIES_ACTIVATIONS_ENDPOINT_END = "/activation"

/**
 * Endpoint for intervenants of a contract.
 */
export const GET_INTERVENANTS_ENDPOINT_START = "/api/entity/contrat-client/"
export const GET_INTERVENANTS_ENDPOINT_END = "/intervenants"
export const GET_INTERVENANTS_SEARCH_ENDPOINT_END = "/recherche"

/**
 * Endpoint for kinds way address resources
 */
export const GET_ALL_KINDS_WAY_ADDRESS_ENDPOINT = ENTITY_API + KINDS_WAY_ADDRESS

/**
 * Endpoint for legal forms resources
 */
export const GET_ALL_LEGAL_FORMS_ENDPOINT = ENTITY_API + LEGAL_FORMS

/**
 * Endpoint for kinds way address resources
 */
export const GET_ALL_TYPES_WAY_ADDRESS_ENDPOINT = ENTITY_API + TYPES_WAY_ADDRESS

/**
 * Endpoint to get products of a contract.
 */
export const GET_PRODUCTS_ENDPOINT_START = "/api/entity/contrat-client/"
export const GET_PRODUCTS_ENDPOINT_END = "/produits"

/**
 * Endpoint to create an order deposit
 */
export const CREATE_ORDER_DEPOSIT_ENPDOINT = "/api/entity/depot-commande"

/**
 * Endpoint to retrieve order deposits
 */
export const RETRIEVE_ORDER_DEPOSITS_ENDPOINT_START = "/api/entity/contrat-client/"
export const RETRIEVE_ORDER_DEPOSITS_ENDPOINT_END = "/depot-commandes"

/**
 * Endpoint to verify a marketing operation.
 */
export const VERIFY_MARKETING_OPERATION_ENDPOINT = "/api/entity/operation-marketing/verification"

/**
 * Endpoint to retrieve all types of service
 */
export const RETRIEVE_ALL_SERVICE_TYPES_ENDPOINT = "/api/entity/prestation-limitation"

/**
 * Endpoint to retrieve categories of staff
 */
export const RETRIEVE_STAFF_CATEGORIES_ENDPOINT_START = "/api/entity/contrat-client/"
export const RETRIEVE_STAFF_CATEGORIES_ENDPOINT_END = "/categorie-personnel"

/**
 * Endpoint to create a session group
 */
export const CREATE_SESSION_GROUP_ENDPOINT = "/api/entity/groupe-session"

/**
 * Endpoint to retrieve groups of session
 */
export const RETRIEVE_SESSION_GROUPS_ENDPOINT_START = "/api/entity/contrat-client/"
export const RETRIEVE_SESSION_GROUPS_ENDPOINT_END = "/groupe-session"

/**
 * Endpoint to retrieve orders
 */
export const RETRIEVE_ORDERS_ENDPOINT_START = ENTITY_API + CONTRACT + "/"
export const RETRIEVE_ORDERS_ENDPOINT_END = ORDERS

export const RETRIEVE_ORDER_ENDPOINT = ENTITY_API + ORDERS
export const RETRIEVE_PURCHASE_ORDER_ENDPOINT = id => ENTITY_API + ORDERS + "/" + id + "/bon-commande"

/**
 * Endpoint to retrieve order lines
 */
export const RETRIEVE_ORDER_LINES_ENDPOINT_START = ENTITY_API + ORDERS + "/"
export const RETRIEVE_ORDER_LINES_ENDPOINT_END = ORDER_LINES

/**
 * Endpoint to retrieve billing statuses
 */
export const RETRIEVE_BILLING_STATUSES_ENDPOINT = "/api/entity/ref-statut-facturation"

/**
 * Endpoint to retrieve a management request.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"

/**
 * Endpoint to search management requests.
 */
export const RETRIEVE_MANAGEMENT_REQUESTS_SEARCH_ENDPOINT_END = "/recherche"

/**
 * Endpoint to get all creators interlocutors
 */
export const RETRIEVE_MANAGEMENT_CREATORS_INTERLOCUTORS_ENDPOINT = "/interlocuteurs-createurs"

/**
 * Endpoint to get all interlocutors in charge
 */
export const RETRIEVE_MANAGEMENT_IN_CHARGE_INTERLOCUTORS_ENDPOINT = "/interlocuteurs-en-charge"

/**
 * Endpoint to create a management request.
 */
export const CREATE_MANAGEMENT_REQUEST_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST

/**
 * Endpoint to update a management request.
 */
export const UPDATE_MANAGEMENT_REQUEST_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"

/**
 * Endpoint to retrieve management request follow ups.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"
export const RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_ENDPOINT_END = "/suivi"

/**
 * Endpoint to add a new management request follow up.
 */
export const ADD_MANAGEMENT_REQUEST_FOLLOW_UP_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"
export const ADD_MANAGEMENT_REQUEST_FOLLOW_UP_ENDPOINT_END = "/suivi"

/**
 * Endpoint to retrieve management request files.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FILES_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"
export const RETRIEVE_MANAGEMENT_REQUEST_FILES_ENDPOINT_END = "/fichiers"

/**
 * Endpoint to upload management request files.
 */
export const UPLOAD_MANAGEMENT_REQUEST_FILES_ENDPOINT_START = ENTITY_API + MANAGEMENT_REQUEST + "/"
export const UPLOAD_MANAGEMENT_REQUEST_FILES_ENDPOINT_END = "/fichier"

/**
 * Endpoints to retrieve bills of an order
 */
export const RETRIEVE_BILLS_ENDPOINT_START = ENTITY_API + ORDERS + "/"
export const RETRIEVE_BILLS_ENDPOINT_END = BILLS

/**
 * Contracts endpoints
 */
export const CONTRACT_ENDPOINTS = {
    retrieveBeneficiaryAreaParameter: contractId => ENTITY_API + CONTRACT + "/" + contractId + "/param-espace-compte",
}

export const TUTORS_ENDPOINTS = {
    createBeneficiaryInterlocutor: (contractId, interlocutorId) =>
        ENTITY_API + CONTRACT + "/" + contractId + TUTORS + "/" + interlocutorId + "/interlocuteur-beneficiaires",
    createTutor: contractId => ENTITY_API + CONTRACT + "/" + contractId + TUTORS,
    deleteBeneficiaryInterlocutor: (contractId, interlocutorId, interlocutorBeneficiaryId) =>
        ENTITY_API +
        CONTRACT +
        "/" +
        contractId +
        TUTORS +
        "/" +
        interlocutorId +
        "/interlocuteur-beneficiaires" +
        "/" +
        interlocutorBeneficiaryId,
    deleteTutor: (contractId, interlocutorId) =>
        ENTITY_API + CONTRACT + "/" + contractId + TUTORS + "/" + interlocutorId,
    generateBeneficiaryBypassLoginToken: (contractId, beneficiaryId) =>
        ENTITY_API +
        CONTRACT +
        "/" +
        contractId +
        TUTORS +
        "/acces-compte/beneficiaires/" +
        beneficiaryId +
        "/generer-jeton",
    retrieveBeneficiariesForAccess: contractId =>
        ENTITY_API + CONTRACT + "/" + contractId + TUTORS + "/acces-compte/beneficiaires",
    retrieveInterlocutorBeneficiaries: (contractId, interlocutorId) =>
        ENTITY_API + CONTRACT + "/" + contractId + TUTORS + "/" + interlocutorId + "/interlocuteur-beneficiaires",
    retrieveTutors: contractId => ENTITY_API + CONTRACT + "/" + contractId + TUTORS,
    updateBeneficiaryInterlocutor: (contractId, interlocutorId, interlocutorBeneficiaryId) =>
        ENTITY_API +
        CONTRACT +
        "/" +
        contractId +
        TUTORS +
        "/" +
        interlocutorId +
        "/interlocuteur-beneficiaires" +
        "/" +
        interlocutorBeneficiaryId,
    updateTutor: (contractId, interlocutorId) =>
        ENTITY_API + CONTRACT + "/" + contractId + TUTORS + "/" + interlocutorId,
}

/**
 * Banks endpoint
 */
export const BANKS_ENDPOINTS = {
    retrieveById: id => ENTITY_API + BANKS + "/" + id,
    search: () => ENTITY_API + BANKS,
}

/**
 * Bank accounts
 */
export const BANK_ACCOUNT_ENDPOINTS = {
    retrieve: () => ENTITY_API + BANK_ACCOUNTS,
}

/**
 * Order reasons endpoints
 */
export const ORDER_REASONS_ENDPOINTS = {
    retrieveAll: ENTITY_API + ORDER_REASONS,
}

/**
 * Order types endpoints
 */
export const ORDER_TYPES_ENDPOINTS = {
    retrieveByContract: contractId => ENTITY_API + ORDER_TYPES + "/" + contractId,
}

/**
 * Title state types endpoints
 */
export const TITLE_STATE_TYPES_ENDPOINTS = ENTITY_API + TITLE_STATE_TYPES

/***************************************/
/*              ACTUATOR               */
/***************************************/

export const ACTUATOR_ENDPOINTS = {
    getAPIHealth: "/actuator/health",
}

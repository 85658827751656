/**
 * Indicate that we launched a request to retrieve the beneficiaries.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARIES_REQUEST = "RETRIEVE_BENEFICIARIES_REQUEST"

/**
 * Indicate that we retrieved successfully the beneficiaries.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARIES_SUCCESS = "RETRIEVE_BENEFICIARIES_SUCCESS"

/**
 * Indicate that we didn't receive the beneficiaries.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARIES_FAILURE = "RETRIEVE_BENEFICIARIES_FAILURE"

/**
 * Indicate that we launched a request to change activations for beneficiaries.
 * @type {string}
 */
export const CHANGE_BENEFICIARIES_ACTIVATIONS_REQUEST = "CHANGE_BENEFICIARIES_ACTIVATIONS_REQUEST"

/**
 * Indicate that the request to change activations for beneficiaries was a success.
 * @type {string}
 */
export const CHANGE_BENEFICIARIES_ACTIVATIONS_SUCCESS = "CHANGE_BENEFICIARIES_ACTIVATIONS_SUCCESS"

/**
 * Indicate that the request to change activations for beneficiaries failed.
 * @type {string}
 */
export const CHANGE_BENEFICIARIES_ACTIVATIONS_FAILURE = "CHANGE_BENEFICIARIES_ACTIVATIONS_FAILURE"

/**
 * Indicate that we want to display details of selected beneficiary
 * @type {string}
 */
export const VIEW_DETAILS_BENEFICIARY = "VIEW_DETAILS_BENEFICIARY"

/**
 * Indicate that we don't want to display details of a beneficiary
 * @type {string}
 */
export const CLEAR_DETAILS_BENEFICIARY = "CLEAR_DETAILS_BENEFICIARY"

/**
 * Indicate that we launched a request to retrieve beneficiary active cesu accounts
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_REQUEST = "RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary active cesu accounts was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_SUCCESS = "RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary active cesu accounts failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_FAILURE = "RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary account statement
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_REQUEST = "RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary account statement was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_SUCCESS = "RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary account statement failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_FAILURE = "RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary checkbook statement
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_REQUEST = "RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary checkbook statement was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_SUCCESS = "RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary checkbook statement failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_FAILURE = "RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary convertible cesu checks
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_REQUEST =
    "RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary convertible cesu checks was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_SUCCESS =
    "RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary convertible cesu checks failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_FAILURE =
    "RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary usable cesu checks
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_REQUEST = "RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary usable cesu checks was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_SUCCESS = "RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary usable cesu checks failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_FAILURE = "RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary opened sessions
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_OPENED_SESSIONS_REQUEST = "RETRIEVE_BENEFICIARY_OPENED_SESSIONS_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary opened sessions was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_OPENED_SESSIONS_SUCCESS = "RETRIEVE_BENEFICIARY_OPENED_SESSIONS_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary opened sessions failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_OPENED_SESSIONS_FAILURE = "RETRIEVE_BENEFICIARY_OPENED_SESSIONS_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary order history
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ORDER_HISTORY_REQUEST = "RETRIEVE_BENEFICIARY_ORDER_HISTORY_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary order history  was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ORDER_HISTORY_SUCCESS = "RETRIEVE_BENEFICIARY_ORDER_HISTORY_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary order history failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ORDER_HISTORY_FAILURE = "RETRIEVE_BENEFICIARY_ORDER_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary account payments history
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_REQUEST =
    "RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary account payments history was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_SUCCESS =
    "RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary account payments history failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_FAILURE =
    "RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary intervenants for payment
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_REQUEST = "RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary intervenants for payment was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_SUCCESS = "RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary intervenants for payment failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_FAILURE = "RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary cesu checks for intervenant payment
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_REQUEST =
    "RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary cesu checks for intervenant payment was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_SUCCESS =
    "RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary cesu checks for intervenant payment failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_FAILURE =
    "RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary check payments history
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_REQUEST = "RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary check payments history was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_SUCCESS = "RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary check payments history failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_FAILURE = "RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to retrieve beneficiary tax document
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_TAX_DOCUMENT_REQUEST = "RETRIEVE_BENEFICIARY_TAX_DOCUMENT_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary  tax document was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_TAX_DOCUMENT_SUCCESS = "RETRIEVE_BENEFICIARY_TAX_DOCUMENT_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary tax document failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_TAX_DOCUMENT_FAILURE = "RETRIEVE_BENEFICIARY_TAX_DOCUMENT_FAILURE"

/**
 * Indique que la requête pour retrouver les commandes EBS du bénéficiaire a été lancé
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COMMANDES_EBS_REQUEST = "RETRIEVE_BENEFICIARY_COMMANDES_EBS_REQUEST"

/**
 * Indique que la requête pour retrouver les commandes EBS du bénéficiaire a réussi
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COMMANDES_EBS_SUCCESS = "RETRIEVE_BENEFICIARY_COMMANDES_EBS_SUCCESS"

/**
 * Indique que la requête pour retrouver les commandes EBS du bénéficiaire a échoué
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COMMANDES_EBS_FAILURE = "RETRIEVE_BENEFICIARY_COMMANDES_EBS_FAILURE"

/**
 * Indique que la requête pour retrouver les compteurs SAP du bénéficiaire a été lancé
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COUNTERS_SAP_REQUEST = "RETRIEVE_BENEFICIARY_COUNTERS_SAP_REQUEST"

/**
 * Indique que la requête pour retrouver les compteurs SAP du bénéficiaire a réussi
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COUNTERS_SAP_SUCCESS = "RETRIEVE_BENEFICIARY_COUNTERS_SAP_SUCCESS"

/**
 * Indique que la requête pour retrouver les compteurs SAP du bénéficiaire a échoué
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_COUNTERS_SAP_FAILURE = "RETRIEVE_BENEFICIARY_COUNTERS_SAP_FAILURE"

import {defaults, interceptors} from "axios"
import dayjs from "dayjs"
import {omit} from "lodash"
import {disconnectUser} from "../actions/sessionStorage/user/userActions"
import {CONNECT_USER_SUCCESS} from "../actions/sessionStorage/user/userActionTypes"
import {APPLICATION_UNAVAILABLE_URL} from "../constants/links"
import {getApiUrl} from "./apiUrl"

export const addTokenToHeader = token => {
    defaults.headers.common["Authorization"] = token
}

export const removeTokenFromHeader = () => {
    defaults.headers.common = omit(defaults.headers.common, "Authorization")
}

/**
 * Configure axios global variables.
 */
const configureAxios = (
    store,
    history,
    getExpirationDate,
    getUserToken,
    pagesArchitectures,
    pages,
    getPermissionsSelector,
) => {
    // Configure differently for production and development/test.
    defaults.baseURL = getApiUrl()

    // Configurer Axios pour inclure les credentials dans toutes les requêtes, afin de permettre l'échange de cookies
    defaults.withCredentials = true

    interceptors.request.use(config => {
        const expirationDate = getExpirationDate(store.getState())

        if (expirationDate) {
            // Expiration date is received in UTC to prevent authentication issue due to client time zone
            let date = dayjs.utc(expirationDate, "DD/MM/YYYY HH:mm:ss", true)

            // If connection token is expired, disconnect the user.
            if (!date.isValid() || date.isBefore(dayjs.utc())) {
                store.dispatch(disconnectUser())

                // Remove the Authorization for the config headers.
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        common: {
                            ...omit(config.headers.common, "Authorization"),
                        },
                    },
                }
            }
        }

        return config
    })

    // Add middleware on axios request response to handle 401 errors and token issues.
    interceptors.response.use(
        response => {
            // The new token is return in a header
            const newToken = response.headers["x-auth-token"]
            // With its new expiration date
            const newExpirationDate = response.headers["x-auth-token-expires-at"]
            if (newToken) {
                // Update the token for axios request to our API
                addTokenToHeader(newToken)

                // Reconnect the user with new token, new expiration date, but same permissions
                // This avoid getting disconnected by the front when it checks the expiration date
                store.dispatch({
                    type: CONNECT_USER_SUCCESS,
                    response: {
                        token: newToken,
                        expirationDate: newExpirationDate,
                        permissions: getPermissionsSelector(store.getState()),
                    },
                })
            }

            return response
        },
        error => {
            if (error.response && error.response.status === 401) {
                // Error 401 : token isn't good anymore.
                // Ask to refresh the token ?

                // On error.
                // Disconnect user.
                store.dispatch(disconnectUser())

                // Move to connection page.
                history.push(pagesArchitectures[pages.LOGIN].route)
            } else if (error.response && error.response.status === 503) {
                // API is unavailable, redirect user to maintenance page
                // Works only if there is a web server in front of the API
                // In others terms, not in the actual dev environment
                window.location.href = APPLICATION_UNAVAILABLE_URL

                // Stop the promise propagation
                return new Promise(() => {})
            }

            // Transform error response type for blob (like downloading a file)
            // When the error response is indeed a json
            // See https://github.com/axios/axios/issues/815#issuecomment-453963910
            // Because Axios does not permit a different response type in their options
            if (
                error.request &&
                error.request.responseType === "blob" &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf("json") !== -1
            ) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader()

                    // After reading, this will parse the content to JSON
                    reader.onload = () => {
                        error.response.data = JSON.parse(reader.result)
                        resolve(Promise.reject(error))
                    }

                    reader.onerror = () => {
                        reject(error)
                    }

                    // Read the Blob
                    reader.readAsText(error.response.data)
                })
            }

            return Promise.reject(error)
        },
    )

    const token = getUserToken(store.getState())
    if (token) {
        addTokenToHeader(token)
    }

    return interceptors
}

export default configureAxios

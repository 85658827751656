import {combineReducers} from "redux"
import {reducer as form} from "redux-form"
import {DISCONNECT_USER} from "../shared-components/actions/sessionStorage/user/userActionTypes"
import entities from "../shared-components/store/entities/entitiesReducer"
import ids from "../shared-components/store/ids/idsReducer"
import notifications from "../shared-components/store/notifications/notificationsReducer"
import localStorage from "./localStorage/localStorageReducer"
import requests from "./requests/requestsReducer"
import sessionStorage from "./sessionStorage/sessionStorageReducer"
import temporaries from "./temporaries/temporariesReducer"

/**
 * Combine reducers to get the store structure.
 */
const appReducers = (state = {}, action = {}) => {
    return combineReducers({
        ids,
        entities,
        localStorage,
        requests,
        sessionStorage,
        temporaries,
        form,
        notifications,
    })(state, action)
}

// We need a parent reducer to re-initalize it
// See post : https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const reducers = (state = {}, action = {}) => {
    // Clear the whole state after user disconnection
    // Except the isResponsive information
    if (action.type === DISCONNECT_USER) {
        state = {
            localStorage: {
                cookies: state.localStorage.cookies,
                displayFullScreenLoader: state.localStorage.displayFullScreenLoader,
                genericLocalStorage: {
                    LOCAL_STORAGE_PERSO_NUMERO_CLIENT:
                        state?.localStorage?.genericLocalStorage?.LOCAL_STORAGE_PERSO_NUMERO_CLIENT,
                },
            },
            requests: {
                PERSONNALISATION_LOGO: state?.requests?.PERSONNALISATION_LOGO,
                PERSONNALISATION: state?.requests?.PERSONNALISATION,
            },
        }
    }

    return appReducers(state, action)
}

export default reducers

export const CommonRequestKeys = {
    CHANGE_ACTUAL_PASSWORD: "CHANGE_ACTUAL_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    GET_BYPASS_TOKEN_SERVIDOM: "GET_BYPASS_TOKEN_SERVIDOM",
    GET_BYPASS_TOKEN_SIDOMI: "GET_BYPASS_TOKEN_SIDOMI",
    GET_PARAM_BYPASS: "GET_PARAM_BYPASS",
    PARAMETER_REQUEST: "PARAMETER_REQUEST",
    RECOVER_LOGIN_PASSWORD: "RECOVER_LOGIN_PASSWORD",
    RECOVER_LOGIN: "RECOVER_LOGIN",
    SEARCH_TOWN: "SEARCH_TOWN",
    USER_AUTHORITY: "USER_AUTHORITY",
    USER_INFO: "USER_INFO",
    USER_LOGOUT: "USER_LOGOUT",
    USER_PROFILE_INFO: "USER_PROFILE_INFO",
}
